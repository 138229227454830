export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  CountryCode: { input: string; output: string; }
  Cursor: { input: string; output: string; }
  Date: { input: string; output: string; }
  DateTime: { input: Date; output: string; }
  LocalTime: { input: string; output: string; }
  Upload: { input: Blob | File; output: never; }
};

export type Allocation = {
  __typename?: 'Allocation';
  count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  nightOf: Scalars['Date']['output'];
};

export type AllocationInput = {
  count: Scalars['Int']['input'];
  nightOf: Scalars['Date']['input'];
};

export type AssignedInclusion = Node & {
  __typename?: 'AssignedInclusion';
  id: Scalars['ID']['output'];
  tags: Array<Tag>;
};

export type AssignedInclusionTypeInput = {
  id: Scalars['ID']['input'];
  tags: Array<Tag>;
};

export type BaseInclusion = {
  categoryId: Scalars['ID']['output'];
  icon: InclusionIcon;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  tags: Array<Tag>;
  title: Scalars['String']['output'];
};

export type BasicRatePlan = {
  advancePurchase?: Maybe<Scalars['Int']['output']>;
  cancellationPolicy: CancellationPolicy;
  commissionRate: Scalars['Float']['output'];
  defaultMaximumStay?: Maybe<Scalars['Int']['output']>;
  defaultMinimumStay?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exchangeRate: Scalars['Float']['output'];
  exchangeRateType: ExchangeRateType;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inclusionCodes: Array<Scalars['String']['output']>;
  inclusions: Array<AssignedInclusion>;
  legacyOfferId?: Maybe<Scalars['Int']['output']>;
  legacyOfferMaps: Array<LegacyOfferMap>;
  name: Scalars['String']['output'];
  paymentTerm: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  property: Property;
  roomTypes: Array<RatePlanRoomType>;
  saleEnd?: Maybe<Scalars['DateTime']['output']>;
  saleStart?: Maybe<Scalars['DateTime']['output']>;
  status: RatePlanStatus;
};


export type BasicRatePlanLegacyOfferIdArgs = {
  roomId: Scalars['ID']['input'];
};


export type BasicRatePlanRoomTypesArgs = {
  input?: InputMaybe<RatePlansInput>;
};

export type BulkUpdateRatePlanInput = {
  baseNetRate: Scalars['Float']['input'];
  id: Scalars['Int']['input'];
  maximumStay: Scalars['Int']['input'];
  minimumStay: Scalars['Int']['input'];
};

export type BulkUpdateRatesInput = {
  allocation?: InputMaybe<Scalars['Int']['input']>;
  endDate: Scalars['Date']['input'];
  ratePlans: Array<BulkUpdateRatePlanInput>;
  startDate: Scalars['Date']['input'];
  weekdays: Array<Scalars['Int']['input']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum CampaignType {
  Exclusive = 'Exclusive',
  Opaque = 'Opaque',
  Standard = 'Standard'
}

export type CancellationPolicy = {
  __typename?: 'CancellationPolicy';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type CancellationWindow = {
  __typename?: 'CancellationWindow';
  charges?: Maybe<Charges>;
  endTime?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export enum Category {
  Custom = 'Custom',
  Experiences = 'Experiences',
  FoodAndDrinks = 'FoodAndDrinks',
  Generic = 'Generic',
  Kids = 'Kids',
  Leisure = 'Leisure',
  Property = 'Property',
  Room = 'Room',
  Transport = 'Transport',
  Wellness = 'Wellness'
}

export type Chain = {
  __typename?: 'Chain';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ChainsInput = {
  combined?: InputMaybe<Scalars['Boolean']['input']>;
  inventoryProviderCode?: InputMaybe<Scalars['String']['input']>;
  query: Scalars['String']['input'];
};

export type Charges = {
  __typename?: 'Charges';
  penalty?: Maybe<Penalty>;
  total?: Maybe<ReservationMoney>;
};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
};

export type Contact = {
  __typename?: 'Contact';
  confirmationEmails?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  vccInEmail?: Maybe<Scalars['Boolean']['output']>;
};

export enum ContactType {
  Billing = 'BILLING',
  Primary = 'PRIMARY',
  Reservation = 'RESERVATION'
}

export type Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type CustomInclusion = BaseInclusion & {
  __typename?: 'CustomInclusion';
  categoryId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  icon: InclusionIcon;
  id: Scalars['ID']['output'];
  propertyId: Scalars['ID']['output'];
  ratePlans: Array<RatePlan>;
  relatedInclusionId?: Maybe<Scalars['ID']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  tags: Array<Tag>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DateRange = {
  max?: InputMaybe<Scalars['Date']['input']>;
  min?: InputMaybe<Scalars['Date']['input']>;
};

export enum DatesType {
  All = 'all',
  Range = 'range'
}

export type Discount = {
  __typename?: 'Discount';
  amount: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export type DiscountInput = {
  amount: Scalars['Float']['input'];
  type: Scalars['String']['input'];
};

export type Edge = {
  cursor: Scalars['Cursor']['output'];
};

export enum ExchangeRateType {
  Dynamic = 'Dynamic',
  Fixed = 'Fixed'
}

export type Fx = {
  __typename?: 'FX';
  convertTo: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  exchangeRate: Scalars['Float']['output'];
  updatedAt: Scalars['String']['output'];
};

export type FixedCost = {
  __typename?: 'FixedCost';
  total?: Maybe<ReservationMoney>;
};

export type GlobalInclusion = BaseInclusion & {
  __typename?: 'GlobalInclusion';
  categoryId: Scalars['ID']['output'];
  icon: InclusionIcon;
  id: Scalars['ID']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  tags: Array<Tag>;
  title: Scalars['String']['output'];
};

export type Image = {
  __typename?: 'Image';
  caption?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  subjectIds: Array<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  /** @deprecated use Image.url(size: desktopSearch) */
  urlDesktopSearch: Scalars['String']['output'];
  /** @deprecated use Image.url(size: preview) */
  urlPreview: Scalars['String']['output'];
  /** @deprecated use Image.url(size: thumbLarge) */
  urlThumbLarge: Scalars['String']['output'];
};


export type ImageUrlArgs = {
  size?: InputMaybe<ImageSize>;
};

export enum ImageSize {
  DesktopSearch = 'desktopSearch',
  Original = 'original',
  Preview = 'preview',
  ThumbLarge = 'thumbLarge'
}

export type InclusionCategory = {
  __typename?: 'InclusionCategory';
  id: Scalars['ID']['output'];
  name: Category;
};

export type InclusionIcon = {
  __typename?: 'InclusionIcon';
  jetstar: Scalars['String']['output'];
  qantas: Scalars['String']['output'];
};

export type InclusionSet = {
  __typename?: 'InclusionSet';
  categories: Array<InclusionCategory>;
  custom: Array<CustomInclusion>;
  global: Array<GlobalInclusion>;
};

export type Inventory = {
  __typename?: 'Inventory';
  id: Scalars['ID']['output'];
  offerId: Scalars['Int']['output'];
  ratePlan: RatePlan;
  rates: Array<Rate>;
};

export type InventoryInput = {
  baseNetRate: Scalars['Float']['input'];
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  count: Scalars['Int']['input'];
  cta?: InputMaybe<Scalars['Boolean']['input']>;
  ctd?: InputMaybe<Scalars['Boolean']['input']>;
  maximumStay?: InputMaybe<Scalars['Int']['input']>;
  minimumStay: Scalars['Int']['input'];
  nightOf: Scalars['Date']['input'];
  rateId: Scalars['ID']['input'];
  ratePlanId: Scalars['ID']['input'];
};

export enum InventoryProvider {
  Derbysoft = 'derbysoft',
  Expedia = 'expedia',
  Extranet = 'extranet',
  Pegasus = 'pegasus',
  Siteconnect = 'siteconnect'
}

export type LegacyInclusion = {
  __typename?: 'LegacyInclusion';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LegacyOfferMap = {
  __typename?: 'LegacyOfferMap';
  offerId: Scalars['Int']['output'];
  ratePlanRoomTypeId: Scalars['ID']['output'];
  roomId: Scalars['ID']['output'];
  roomIntId: Scalars['Int']['output'];
};

export type Link = {
  __typename?: 'Link';
  href?: Maybe<Scalars['String']['output']>;
};

export type Location = {
  __typename?: 'Location';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LuxuryRatePlan = BasicRatePlan & Node & {
  __typename?: 'LuxuryRatePlan';
  advancePurchase?: Maybe<Scalars['Int']['output']>;
  cancellationPolicy: CancellationPolicy;
  commissionRate: Scalars['Float']['output'];
  conditions: Scalars['String']['output'];
  defaultMaximumStay?: Maybe<Scalars['Int']['output']>;
  defaultMinimumStay?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exchangeRate: Scalars['Float']['output'];
  exchangeRateType: ExchangeRateType;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inclusionCodes: Array<Scalars['String']['output']>;
  inclusions: Array<AssignedInclusion>;
  legacyOfferId?: Maybe<Scalars['Int']['output']>;
  legacyOfferMaps: Array<LegacyOfferMap>;
  name: Scalars['String']['output'];
  paymentTerm: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  promotionCode: PromotionCode;
  property: Property;
  roomTypes: Array<RatePlanRoomType>;
  saleEnd?: Maybe<Scalars['DateTime']['output']>;
  saleStart?: Maybe<Scalars['DateTime']['output']>;
  status: RatePlanStatus;
};


export type LuxuryRatePlanLegacyOfferIdArgs = {
  roomId: Scalars['ID']['input'];
};


export type LuxuryRatePlanRoomTypesArgs = {
  input?: InputMaybe<RatePlansInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  archivePromotion: Promotion;
  archiveProperty: Property;
  archiveRatePlan: Scalars['Boolean']['output'];
  bulkUpdateRates: UpdateRatesResponse;
  deleteImages: Array<Image>;
  deleteProperty: Property;
  deleteUser: Scalars['Boolean']['output'];
  publishProperty: Property;
  removeCustomInclusion: Scalars['Boolean']['output'];
  reorderImages: Array<Image>;
  republishProperty: Property;
  resendInvitation: Scalars['Boolean']['output'];
  saveCustomInclusion: CustomInclusion;
  saveKeyContact: Property;
  saveLuxuryRatePlan: LuxuryRatePlan;
  saveOpaqueRatePlan: OpaqueRatePlan;
  savePromotion: Promotion;
  saveRatePlan: RatePlan;
  saveRates: SaveRatesResult;
  unpublishProperty: Property;
  updateImages: Array<Image>;
  updateProperty: Property;
  updateRates: UpdateRatesResponse;
  uploadImage: Image;
  validateToken?: Maybe<ResponseToken>;
};


export type MutationArchivePromotionArgs = {
  id: Scalars['Int']['input'];
  propertyId: Scalars['Int']['input'];
};


export type MutationArchivePropertyArgs = {
  id: Scalars['Int']['input'];
};


export type MutationArchiveRatePlanArgs = {
  id: Scalars['ID']['input'];
};


export type MutationBulkUpdateRatesArgs = {
  input: BulkUpdateRatesInput;
  propertyId: Scalars['Int']['input'];
  roomTypeId: Scalars['Int']['input'];
};


export type MutationDeleteImagesArgs = {
  ids: Array<Scalars['String']['input']>;
  propertyId: Scalars['String']['input'];
};


export type MutationDeletePropertyArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPublishPropertyArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveCustomInclusionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationReorderImagesArgs = {
  input: Array<ReorderImageInput>;
  propertyId: Scalars['String']['input'];
  subjectId: Scalars['String']['input'];
};


export type MutationRepublishPropertyArgs = {
  id: Scalars['Int']['input'];
};


export type MutationResendInvitationArgs = {
  propertyId: Scalars['ID']['input'];
};


export type MutationSaveCustomInclusionArgs = {
  input: SaveCustomInclusionInput;
};


export type MutationSaveKeyContactArgs = {
  input: SaveKeyContactInput;
};


export type MutationSaveLuxuryRatePlanArgs = {
  input: SaveLuxuryRatePlanInput;
};


export type MutationSaveOpaqueRatePlanArgs = {
  input: SaveOpaqueRatePlanInput;
};


export type MutationSavePromotionArgs = {
  input: SavePromotionInput;
};


export type MutationSaveRatePlanArgs = {
  input: SaveRatePlanInput;
};


export type MutationSaveRatesArgs = {
  input: SaveRatesInput;
};


export type MutationUnpublishPropertyArgs = {
  id: Scalars['Int']['input'];
};


export type MutationUpdateImagesArgs = {
  input: Array<UpdateImageInput>;
  propertyId: Scalars['String']['input'];
};


export type MutationUpdatePropertyArgs = {
  id: Scalars['Int']['input'];
  input: UpdatePropertyInput;
};


export type MutationUpdateRatesArgs = {
  input: UpdateRatesInput;
  propertyId: Scalars['Int']['input'];
  roomTypeId: Scalars['Int']['input'];
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload']['input'];
  input: UploadImageInput;
  propertyId: Scalars['String']['input'];
};


export type MutationValidateTokenArgs = {
  token: Scalars['String']['input'];
};

export type NightsCost = {
  __typename?: 'NightsCost';
  numberOfNights?: Maybe<Scalars['String']['output']>;
  total?: Maybe<ReservationMoney>;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type OpaqueRatePlan = BasicRatePlan & Node & {
  __typename?: 'OpaqueRatePlan';
  advancePurchase?: Maybe<Scalars['Int']['output']>;
  cancellationPolicy: CancellationPolicy;
  commissionRate: Scalars['Float']['output'];
  defaultMaximumStay?: Maybe<Scalars['Int']['output']>;
  defaultMinimumStay?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exchangeRate: Scalars['Float']['output'];
  exchangeRateType: ExchangeRateType;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inclusionCodes: Array<Scalars['String']['output']>;
  inclusions: Array<AssignedInclusion>;
  legacyOfferId?: Maybe<Scalars['Int']['output']>;
  legacyOfferMaps: Array<LegacyOfferMap>;
  name: Scalars['String']['output'];
  paymentTerm: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  promotionCode: PromotionCode;
  property: Property;
  roomTypes: Array<RatePlanRoomType>;
  saleEnd?: Maybe<Scalars['DateTime']['output']>;
  saleStart?: Maybe<Scalars['DateTime']['output']>;
  status: RatePlanStatus;
};


export type OpaqueRatePlanLegacyOfferIdArgs = {
  roomId: Scalars['ID']['input'];
};


export type OpaqueRatePlanRoomTypesArgs = {
  input?: InputMaybe<RatePlansInput>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int']['output'];
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type Payment = {
  __typename?: 'Payment';
  commission?: Maybe<Scalars['Float']['output']>;
  term?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PaymentInput = {
  commission?: InputMaybe<Scalars['Float']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentTerm = {
  __typename?: 'PaymentTerm';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PaymentType = {
  __typename?: 'PaymentType';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  paymentTerms: Array<PaymentTerm>;
};

export type Penalty = {
  __typename?: 'Penalty';
  fixedCost?: Maybe<FixedCost>;
  nightsCost?: Maybe<NightsCost>;
  percentCostOfStay?: Maybe<PercentCostOfStay>;
  total?: Maybe<ReservationMoney>;
};

export type PercentCostOfStay = {
  __typename?: 'PercentCostOfStay';
  percentage?: Maybe<Scalars['String']['output']>;
  total?: Maybe<ReservationMoney>;
};

export type PersonName = {
  __typename?: 'PersonName';
  first?: Maybe<Scalars['String']['output']>;
  last?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  cancellationPolicyId?: Maybe<Scalars['Int']['output']>;
  customCancellationPolicy: Scalars['Boolean']['output'];
  customCommercialTerms: Scalars['Boolean']['output'];
  customInclusionCodes: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discount: Discount;
  id: Scalars['Int']['output'];
  inclusionCodes: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  payment?: Maybe<Payment>;
  promotionCodeId?: Maybe<Scalars['Int']['output']>;
  promotionType: Scalars['String']['output'];
  propertyId: Scalars['Int']['output'];
  ratePlanIds: Array<Scalars['Int']['output']>;
  roomTypeIds: Array<Scalars['Int']['output']>;
  saleDates: SaleDates;
  status: PromotionStatus;
  stayDates: StayDates;
};

export type PromotionCode = {
  __typename?: 'PromotionCode';
  campaignCode?: Maybe<Scalars['String']['output']>;
  campaignId?: Maybe<Scalars['Int']['output']>;
  campaignType: CampaignType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type PromotionInput = {
  cancellationPolicyId?: InputMaybe<Scalars['Int']['input']>;
  customCancellationPolicy: Scalars['Boolean']['input'];
  customCommercialTerms: Scalars['Boolean']['input'];
  customInclusionCodes: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  discount: DiscountInput;
  inclusionCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  payment?: InputMaybe<PaymentInput>;
  promotionCodeId?: InputMaybe<Scalars['Int']['input']>;
  promotionType: Scalars['String']['input'];
  ratePlanIds: Array<Scalars['Int']['input']>;
  saleDates: SaleDatesInput;
  stayDates: StayDatesInput;
};

export enum PromotionKind {
  AdvancePurchase = 'advance_purchase',
  BonusPoints = 'bonus_points',
  Classic = 'classic',
  Custom = 'custom',
  FlashSale = 'flash_sale',
  MemberDeals = 'member_deals',
  StayAndSave = 'stay_and_save'
}

export type PromotionRule = {
  __typename?: 'PromotionRule';
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  promotionCode: Scalars['String']['output'];
  promotionCodeId?: Maybe<Scalars['Int']['output']>;
  strategyCode?: Maybe<Scalars['String']['output']>;
};

export enum PromotionStatus {
  Active = 'ACTIVE',
  EndingSoon = 'ENDING_SOON',
  Expired = 'EXPIRED',
  Upcoming = 'UPCOMING'
}

export type PromotionType = {
  __typename?: 'PromotionType';
  cancellationPolicyIds?: Maybe<Array<Scalars['Int']['output']>>;
  code: PromotionKind;
  defaultCommissionRate?: Maybe<Scalars['Float']['output']>;
  rules: Array<PromotionRule>;
};

export type Property = {
  __typename?: 'Property';
  billingContact?: Maybe<Contact>;
  billingContactId?: Maybe<Scalars['Int']['output']>;
  canManageContent: Scalars['Boolean']['output'];
  canManageRates: Scalars['Boolean']['output'];
  chain?: Maybe<Chain>;
  chainId?: Maybe<Scalars['Int']['output']>;
  commissionRate: Scalars['Float']['output'];
  coordinates?: Maybe<Coordinates>;
  country: Scalars['String']['output'];
  currencyCode?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facilities: Array<PropertyFacility>;
  foreignCurrency: Scalars['Boolean']['output'];
  formattedAddress: Scalars['String']['output'];
  fx?: Maybe<Fx>;
  id: Scalars['Int']['output'];
  images: Array<Image>;
  inclusion?: Maybe<CustomInclusion>;
  inclusions?: Maybe<InclusionSet>;
  integrationPartner: Scalars['String']['output'];
  internationalTax?: Maybe<Scalars['Float']['output']>;
  inventoryProvider: InventoryProvider;
  inventoryProviderCode: Scalars['String']['output'];
  mainImage?: Maybe<Image>;
  masterGroup?: Maybe<PropertyGroup>;
  masterGroupId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  numberOfRooms?: Maybe<Scalars['Int']['output']>;
  paymentTerm: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  previousPropertyId?: Maybe<Scalars['Int']['output']>;
  primaryContact?: Maybe<Contact>;
  primaryContactId?: Maybe<Scalars['Int']['output']>;
  promotion?: Maybe<Promotion>;
  promotions: Array<Promotion>;
  propertyCategory?: Maybe<PropertyCategory>;
  propertyCategoryCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated use property.facilities */
  propertyFacilities?: Maybe<Array<PropertyFacility>>;
  /** @deprecated use property.facilities.id */
  propertyFacilityIds?: Maybe<Array<Scalars['Int']['output']>>;
  publishable: Publishable;
  ratePlans: RatePlanConnection;
  ratePlansServiceEnabled: Scalars['Boolean']['output'];
  ratesServiceEnabled: Scalars['Boolean']['output'];
  rating?: Maybe<Scalars['String']['output']>;
  ratingType?: Maybe<RatingType>;
  reservationsContact?: Maybe<Contact>;
  reservationsContactId?: Maybe<Scalars['Int']['output']>;
  room?: Maybe<Room>;
  rooms: Array<Room>;
  state?: Maybe<Scalars['String']['output']>;
  status: Status;
  streetAddress: Scalars['String']['output'];
  streetAddress2?: Maybe<Scalars['String']['output']>;
  suburb: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  /** @deprecated use property.user.email */
  userEmail?: Maybe<Scalars['String']['output']>;
  /** @deprecated use property.user.id */
  userId?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use property.user.isMigrated */
  userIsMigrated?: Maybe<Scalars['Boolean']['output']>;
  uuid: Scalars['String']['output'];
};


export type PropertyImagesArgs = {
  input?: InputMaybe<PropertyImagesInput>;
};


export type PropertyInclusionArgs = {
  id: Scalars['ID']['input'];
};


export type PropertyPromotionArgs = {
  id: Scalars['ID']['input'];
};


export type PropertyRatePlansArgs = {
  input?: InputMaybe<RatePlansConnectionInput>;
};


export type PropertyRoomArgs = {
  id: Scalars['ID']['input'];
};

export type PropertyCategory = {
  __typename?: 'PropertyCategory';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PropertyFacility = {
  __typename?: 'PropertyFacility';
  category?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type PropertyGroup = {
  __typename?: 'PropertyGroup';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  properties: Array<Property>;
  property?: Maybe<Property>;
  totalProperties?: Maybe<Scalars['Int']['output']>;
};


export type PropertyGroupPropertyArgs = {
  id: Scalars['ID']['input'];
};

export type PropertyImagesInput = {
  all: Scalars['Boolean']['input'];
};

export type Publishable = {
  __typename?: 'Publishable';
  billingContact?: Maybe<Scalars['Boolean']['output']>;
  images?: Maybe<Scalars['Boolean']['output']>;
  propertyLocation?: Maybe<Scalars['Boolean']['output']>;
  propertyOverview?: Maybe<Scalars['Boolean']['output']>;
  propertyPolicies?: Maybe<Scalars['Boolean']['output']>;
  ratePlans?: Maybe<Scalars['Boolean']['output']>;
  reservationsContact?: Maybe<Scalars['Boolean']['output']>;
  roomTypes?: Maybe<Scalars['Boolean']['output']>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  apiHealthCheckReservations: ReservationHealthCheck;
  cancellationPolicies: Array<CancellationPolicy>;
  countries: Array<Location>;
  legacyInclusions: Array<LegacyInclusion>;
  masterGroups: Array<PropertyGroup>;
  paymentTypes: Array<PaymentType>;
  promotionCodes: Array<PromotionCode>;
  promotionTypes: Array<PromotionType>;
  property: Property;
  propertyCategories: Array<PropertyCategory>;
  propertyFacilities: Array<PropertyFacility>;
  propertyGroup?: Maybe<PropertyGroup>;
  ratePlans: RatePlanConnection;
  reservation?: Maybe<ReservationConnection>;
  reservations: ReservationsConnection;
  states: Array<Location>;
  viewer?: Maybe<Viewer>;
};


export type QueryLegacyInclusionsArgs = {
  isGlobalInclusionFeatureFlagOn?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPropertyArgs = {
  id: Scalars['Int']['input'];
};


export type QueryPropertyGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRatePlansArgs = {
  input: RatePlansInput;
};


export type QueryReservationArgs = {
  hotelRef: Scalars['ID']['input'];
  propertyId: Scalars['ID']['input'];
};


export type QueryReservationsArgs = {
  filters?: InputMaybe<ReservationFiltersInput>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ReservationSortInput>;
};


export type QueryStatesArgs = {
  country: Scalars['CountryCode']['input'];
};

export type Rate = {
  __typename?: 'Rate';
  baseNetRate: Scalars['Float']['output'];
  cancellationPolicy?: Maybe<CancellationPolicy>;
  cancellationPolicyId?: Maybe<Scalars['Int']['output']>;
  closed?: Maybe<Scalars['Boolean']['output']>;
  cta?: Maybe<Scalars['Boolean']['output']>;
  ctd?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  maximumStay?: Maybe<Scalars['Int']['output']>;
  minimumStay?: Maybe<Scalars['Int']['output']>;
  nightOf: Scalars['Date']['output'];
  propertyId: Scalars['Int']['output'];
  ratePlanId: Scalars['Int']['output'];
  roomTypeId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<UpdatedBy>;
};

export type RateInput = {
  baseNetRate: Scalars['Float']['input'];
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  cta?: InputMaybe<Scalars['Boolean']['input']>;
  ctd?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maximumStay?: InputMaybe<Scalars['Int']['input']>;
  minimumStay: Scalars['Int']['input'];
  nightOf: Scalars['Date']['input'];
  ratePlanId: Scalars['Int']['input'];
};

export type RatePlan = BasicRatePlan & Node & {
  __typename?: 'RatePlan';
  advancePurchase?: Maybe<Scalars['Int']['output']>;
  cancellationPolicy: CancellationPolicy;
  cancellationPolicyId: Scalars['String']['output'];
  commissionRate: Scalars['Float']['output'];
  conditions?: Maybe<Scalars['String']['output']>;
  defaultMaximumStay?: Maybe<Scalars['Int']['output']>;
  defaultMinimumStay?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exchangeRate: Scalars['Float']['output'];
  exchangeRateType: ExchangeRateType;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inclusionCodes: Array<Scalars['String']['output']>;
  inclusions: Array<AssignedInclusion>;
  legacyOfferId?: Maybe<Scalars['Int']['output']>;
  legacyOfferMaps: Array<LegacyOfferMap>;
  name: Scalars['String']['output'];
  paymentTerm: Scalars['String']['output'];
  paymentType: Scalars['String']['output'];
  promotionCode?: Maybe<PromotionCode>;
  property: Property;
  propertyId: Scalars['String']['output'];
  propertyLegacyId?: Maybe<Scalars['Int']['output']>;
  roomTypes: Array<RatePlanRoomType>;
  saleEnd?: Maybe<Scalars['DateTime']['output']>;
  saleStart?: Maybe<Scalars['DateTime']['output']>;
  status: RatePlanStatus;
};


export type RatePlanLegacyOfferIdArgs = {
  roomId: Scalars['ID']['input'];
};


export type RatePlanRoomTypesArgs = {
  input?: InputMaybe<RatePlansInput>;
};

export type RatePlanConnection = Connection & {
  __typename?: 'RatePlanConnection';
  edges: Array<RatePlanEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RatePlanEdge = Edge & {
  __typename?: 'RatePlanEdge';
  cursor: Scalars['Cursor']['output'];
  node: RatePlanUnion;
};

export type RatePlanRoomType = {
  __typename?: 'RatePlanRoomType';
  extraAdultRate: Scalars['Float']['output'];
  extraChildRate: Scalars['Float']['output'];
  extraInfantRate: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  includedOccupants: Scalars['Int']['output'];
  mappingCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roomLegacyId?: Maybe<Scalars['Int']['output']>;
  roomTypeId: Scalars['String']['output'];
};

export type RatePlanRoomTypeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  extraAdultRate?: InputMaybe<Scalars['Float']['input']>;
  extraChildRate?: InputMaybe<Scalars['Float']['input']>;
  extraInfantRate?: InputMaybe<Scalars['Float']['input']>;
  includedOccupants: Scalars['Int']['input'];
  roomTypeId: Scalars['String']['input'];
};

export enum RatePlanStatus {
  Current = 'CURRENT',
  Expired = 'EXPIRED',
  Hidden = 'HIDDEN',
  Upcoming = 'UPCOMING'
}

export type RatePlanUnion = LuxuryRatePlan | OpaqueRatePlan | RatePlan;

export type RatePlansConnectionInput = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<RatePlansFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortByOption>;
};

export type RatePlansFilter = {
  current?: InputMaybe<Scalars['Boolean']['input']>;
  expired?: InputMaybe<Scalars['Boolean']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  rooms?: InputMaybe<Array<Scalars['String']['input']>>;
  upcoming?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RatePlansInput = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  propertyId?: InputMaybe<Scalars['Int']['input']>;
  propertyUuid: Scalars['ID']['input'];
  sortBy?: InputMaybe<SortByOption>;
};

export type RatesInput = {
  days?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum RatingType {
  Aaa = 'AAA',
  SelfRated = 'SELF_RATED'
}

export type ReorderImageInput = {
  id: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type ReservationBooking = {
  __typename?: 'ReservationBooking';
  id?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  travelArranger?: Maybe<ReservationTravelArranger>;
};

export type ReservationBookingDetails = {
  __typename?: 'ReservationBookingDetails';
  booking?: Maybe<ReservationBooking>;
};

export type ReservationCancellationPolicy = {
  __typename?: 'ReservationCancellationPolicy';
  cancellationWindows?: Maybe<Array<Maybe<CancellationWindow>>>;
};

export type ReservationCharges = {
  __typename?: 'ReservationCharges';
  supplier?: Maybe<ReservationSupplier>;
};

export type ReservationConnection = {
  __typename?: 'ReservationConnection';
  id: Scalars['ID']['output'];
  reservationDetails?: Maybe<ReservationDetails>;
  supplierPayments?: Maybe<SupplierPayments>;
};


export type ReservationConnectionSupplierPaymentsArgs = {
  propertyId: Scalars['ID']['input'];
};

export type ReservationDetails = {
  __typename?: 'ReservationDetails';
  createdAt?: Maybe<Scalars['String']['output']>;
  embedded?: Maybe<ReservationBookingDetails>;
  id?: Maybe<Scalars['ID']['output']>;
  links?: Maybe<ReservationLinks>;
  reference?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stay?: Maybe<Stay>;
};

export type ReservationEmbedded = {
  __typename?: 'ReservationEmbedded';
  reservations?: Maybe<Array<ReservationSummary>>;
};

export type ReservationFiltersInput = {
  checkIn?: InputMaybe<DateRange>;
  checkOut?: InputMaybe<DateRange>;
  createdAt?: InputMaybe<DateRange>;
  states?: InputMaybe<Array<ReservationState>>;
};

export type ReservationHealthCheck = {
  __typename?: 'ReservationHealthCheck';
  reservationDetails?: Maybe<Scalars['Boolean']['output']>;
  reservations?: Maybe<Scalars['Boolean']['output']>;
};

export type ReservationInclusion = {
  __typename?: 'ReservationInclusion';
  code?: Maybe<Scalars['String']['output']>;
  iconCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ReservationLeadGuest = {
  __typename?: 'ReservationLeadGuest';
  name?: Maybe<PersonName>;
};

export type ReservationLink = {
  __typename?: 'ReservationLink';
  href?: Maybe<Scalars['String']['output']>;
};

export type ReservationLinks = {
  __typename?: 'ReservationLinks';
  first?: Maybe<Link>;
  next?: Maybe<Link>;
  previous?: Maybe<Link>;
  self?: Maybe<Link>;
  supplierPayments?: Maybe<Link>;
};

export type ReservationMoney = {
  __typename?: 'ReservationMoney';
  amount?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  fxRate?: Maybe<Scalars['String']['output']>;
};

export type ReservationOffer = {
  __typename?: 'ReservationOffer';
  cancellationPolicy?: Maybe<ReservationCancellationPolicy>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  inclusions?: Maybe<Array<Maybe<ReservationInclusion>>>;
  name?: Maybe<Scalars['String']['output']>;
  paymentTerm?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<SupplierPaymentType>;
  promotion?: Maybe<ReservationPromotion>;
};

export type ReservationPromotion = {
  __typename?: 'ReservationPromotion';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ReservationProperty = {
  __typename?: 'ReservationProperty';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ReservationRoomType = {
  __typename?: 'ReservationRoomType';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum ReservationSortBy {
  CheckIn = 'check_in',
  CheckOut = 'check_out',
  CreatedAt = 'created_at'
}

export type ReservationSortInput = {
  column: ReservationSortBy;
  direction: SortOrder;
};

export enum ReservationState {
  Cancelled = 'cancelled',
  Reserved = 'reserved'
}

export type ReservationSummary = {
  __typename?: 'ReservationSummary';
  booking?: Maybe<ReservationBooking>;
  charges?: Maybe<ReservationCharges>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  links?: Maybe<ReservationLinks>;
  reference?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stay?: Maybe<Stay>;
};

export type ReservationSupplier = {
  __typename?: 'ReservationSupplier';
  totalExcCommission?: Maybe<ReservationMoney>;
};

export type ReservationTravelArranger = {
  __typename?: 'ReservationTravelArranger';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<PersonName>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type ReservationsConnection = {
  __typename?: 'ReservationsConnection';
  embedded?: Maybe<ReservationEmbedded>;
  pagination?: Maybe<ReservationsPagination>;
};

export type ReservationsPagination = {
  __typename?: 'ReservationsPagination';
  count?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ResponseToken = {
  __typename?: 'ResponseToken';
  action?: Maybe<Scalars['String']['output']>;
  challengeTime?: Maybe<Scalars['String']['output']>;
  errorCodes?: Maybe<Array<Scalars['String']['output']>>;
  hostname?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum Role {
  Staff = 'staff',
  Supplier = 'supplier'
}

export type Room = Node & {
  __typename?: 'Room';
  allocations: Array<Allocation>;
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images: Array<Image>;
  inventory: Array<Inventory>;
  legacyRoomTypeIntId: Scalars['Int']['output'];
  maximumOccupants: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  ratePlans: RatePlanConnection;
};


export type RoomAllocationsArgs = {
  input?: InputMaybe<RatesInput>;
};


export type RoomInventoryArgs = {
  input?: InputMaybe<RatesInput>;
};


export type RoomRatePlansArgs = {
  input?: InputMaybe<RatePlansConnectionInput>;
};

export type SaleDates = {
  __typename?: 'SaleDates';
  endDateTime?: Maybe<Scalars['DateTime']['output']>;
  startDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated value computed from endDateTime / startDateTime and only used for front-end forms. Compute on client */
  type: DatesType;
};

export type SaleDatesInput = {
  endDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  startDateTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaveCustomInclusionInput = {
  icon: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  propertyId: Scalars['ID']['input'];
  relatedInclusionId: Scalars['ID']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type SaveKeyContactInput = {
  confirmationEmails?: InputMaybe<Scalars['Boolean']['input']>;
  contactType: ContactType;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  position?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  vccInEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaveLuxuryRatePlanInput = {
  advancePurchase?: InputMaybe<Scalars['Int']['input']>;
  cancellationPolicyId: Scalars['String']['input'];
  commissionRate?: InputMaybe<Scalars['Float']['input']>;
  conditions: Scalars['String']['input'];
  defaultMaximumStay?: InputMaybe<Scalars['Int']['input']>;
  defaultMinimumStay?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exchangeRate?: InputMaybe<Scalars['Float']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inclusionCodes: Array<Scalars['String']['input']>;
  inclusions: Array<AssignedInclusionTypeInput>;
  isGlobalInclusionFeatureFlagOn?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  paymentTerm?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<Scalars['String']['input']>;
  promotionCodeId: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
  roomTypes: Array<RatePlanRoomTypeInput>;
  saleEnd?: InputMaybe<Scalars['DateTime']['input']>;
  saleStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaveOpaqueRatePlanInput = {
  advancePurchase?: InputMaybe<Scalars['Int']['input']>;
  cancellationPolicyId: Scalars['String']['input'];
  commissionRate?: InputMaybe<Scalars['Float']['input']>;
  defaultMaximumStay?: InputMaybe<Scalars['Int']['input']>;
  defaultMinimumStay?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exchangeRate?: InputMaybe<Scalars['Float']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inclusionCodes: Array<Scalars['String']['input']>;
  inclusions: Array<AssignedInclusionTypeInput>;
  isGlobalInclusionFeatureFlagOn?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  paymentTerm?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<Scalars['String']['input']>;
  promotionCodeId: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
  roomTypes: Array<RatePlanRoomTypeInput>;
  saleEnd?: InputMaybe<Scalars['DateTime']['input']>;
  saleStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SavePromotionInput = {
  cancellationPolicyId?: InputMaybe<Scalars['Int']['input']>;
  customCancellationPolicy: Scalars['Boolean']['input'];
  customCommercialTerms: Scalars['Boolean']['input'];
  customInclusionCodes: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  discount: DiscountInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  inclusionCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  payment?: InputMaybe<PaymentInput>;
  promotionCodeId?: InputMaybe<Scalars['Int']['input']>;
  promotionType: Scalars['String']['input'];
  propertyId: Scalars['ID']['input'];
  ratePlanIds: Array<Scalars['Int']['input']>;
  saleDates: SaleDatesInput;
  stayDates: StayDatesInput;
};

export type SaveRatePlanInput = {
  advancePurchase?: InputMaybe<Scalars['Int']['input']>;
  cancellationPolicyId: Scalars['String']['input'];
  commissionRate?: InputMaybe<Scalars['Float']['input']>;
  conditions?: InputMaybe<Scalars['String']['input']>;
  defaultMaximumStay?: InputMaybe<Scalars['Int']['input']>;
  defaultMinimumStay?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exchangeRate?: InputMaybe<Scalars['Float']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inclusionCodes: Array<Scalars['String']['input']>;
  inclusions: Array<AssignedInclusionTypeInput>;
  isGlobalInclusionFeatureFlagOn?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  paymentTerm?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<Scalars['String']['input']>;
  promotionCodeId?: InputMaybe<Scalars['String']['input']>;
  propertyId: Scalars['ID']['input'];
  roomTypes: Array<RatePlanRoomTypeInput>;
  saleEnd?: InputMaybe<Scalars['DateTime']['input']>;
  saleStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SaveRatesInput = {
  inventory: Array<InventoryInput>;
  propertyId: Scalars['ID']['input'];
  roomId: Scalars['ID']['input'];
};

export type SaveRatesResult = {
  __typename?: 'SaveRatesResult';
  success: Scalars['Boolean']['output'];
};

export enum SortByOption {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SaleEndAsc = 'SALE_END_ASC',
  SaleEndDesc = 'SALE_END_DESC'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum Status {
  Archived = 'ARCHIVED',
  Invited = 'INVITED',
  Onboarding = 'ONBOARDING',
  Prospective = 'PROSPECTIVE',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type Stay = {
  __typename?: 'Stay';
  adults?: Maybe<Scalars['Int']['output']>;
  cancellationPolicyCustomMessages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  checkIn?: Maybe<Scalars['String']['output']>;
  checkOut?: Maybe<Scalars['String']['output']>;
  children?: Maybe<Scalars['Int']['output']>;
  infants?: Maybe<Scalars['Int']['output']>;
  leadGuest?: Maybe<ReservationLeadGuest>;
  offer?: Maybe<ReservationOffer>;
  property?: Maybe<ReservationProperty>;
  roomType?: Maybe<ReservationRoomType>;
  specialRequests?: Maybe<Scalars['String']['output']>;
};

export type StayDates = {
  __typename?: 'StayDates';
  customAdvancePurchase: Scalars['Boolean']['output'];
  customLengthOfStay?: Maybe<Scalars['Boolean']['output']>;
  daysInAdvance?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  excludedDates: Array<Scalars['Date']['output']>;
  excludedWeekdays: Array<Scalars['Int']['output']>;
  hasExcludedDates: Scalars['Boolean']['output'];
  maximumStay?: Maybe<Scalars['Int']['output']>;
  minimumStay?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  /** @deprecated value computed from endDate / startDate and only used for front-end forms. Compute on client. */
  type: DatesType;
};

export type StayDatesInput = {
  customAdvancePurchase: Scalars['Boolean']['input'];
  customLengthOfStay?: InputMaybe<Scalars['Boolean']['input']>;
  daysInAdvance?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  excludedDates?: InputMaybe<Array<Scalars['Date']['input']>>;
  excludedWeekdays?: InputMaybe<Array<Scalars['Int']['input']>>;
  maximumStay?: InputMaybe<Scalars['Int']['input']>;
  minimumStay?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type SupplierPayment = {
  __typename?: 'SupplierPayment';
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  paymentDetails?: Maybe<SupplierPaymentDetails>;
  paymentType?: Maybe<Scalars['String']['output']>;
  vcc?: Maybe<SupplierPaymentVcc>;
};

export type SupplierPaymentDetails = {
  __typename?: 'SupplierPaymentDetails';
  expiry?: Maybe<Scalars['String']['output']>;
  payFrom?: Maybe<Scalars['String']['output']>;
  payTo?: Maybe<Scalars['String']['output']>;
  total?: Maybe<ReservationMoney>;
};

export enum SupplierPaymentType {
  DirectCredit = 'direct_credit',
  FinanceGeneratedVcc = 'finance_generated_vcc',
  Invoice = 'invoice',
  Vcc = 'vcc'
}

export type SupplierPaymentVcc = {
  __typename?: 'SupplierPaymentVcc';
  cardHolderName?: Maybe<Scalars['String']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  expiry?: Maybe<Scalars['String']['output']>;
  securityCode?: Maybe<Scalars['String']['output']>;
};

export type SupplierPayments = {
  __typename?: 'SupplierPayments';
  embedded?: Maybe<SupplierPaymentsEmbedded>;
};

export type SupplierPaymentsEmbedded = {
  __typename?: 'SupplierPaymentsEmbedded';
  supplierPayments?: Maybe<Array<SupplierPayment>>;
};

export enum Tag {
  Bonus = 'BONUS',
  Popular = 'POPULAR',
  Unknown = 'UNKNOWN'
}

export type UpdateImageInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  subjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdatePropertyInput = {
  chainId?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  masterGroupId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfRooms?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  previousPropertyId?: InputMaybe<Scalars['Int']['input']>;
  propertyCategoryCode?: InputMaybe<Scalars['String']['input']>;
  propertyFacilityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  rating?: InputMaybe<Scalars['String']['input']>;
  ratingType?: InputMaybe<RatingType>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  street_address_2?: InputMaybe<Scalars['String']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRatesInput = {
  allocations: Array<AllocationInput>;
  rates: Array<RateInput>;
};

export type UpdateRatesResponse = {
  __typename?: 'UpdateRatesResponse';
  allocations: Array<Allocation>;
  rates: Array<Rate>;
};

export type UpdatedBy = {
  __typename?: 'UpdatedBy';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UpdatedByInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UploadImageInput = {
  caption: Scalars['String']['input'];
  subjectId: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isMigrated?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  propertyGroupId?: Maybe<Scalars['ID']['output']>;
  role: Role;
};

export type Viewer = {
  __typename?: 'Viewer';
  attributes: User;
  chains: Array<Chain>;
  id: Scalars['ID']['output'];
  isEmailInUse: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  property?: Maybe<Property>;
  ratePlan?: Maybe<RatePlanUnion>;
  signOut: Scalars['Boolean']['output'];
};


export type ViewerChainsArgs = {
  input: ChainsInput;
};


export type ViewerIsEmailInUseArgs = {
  email: Scalars['String']['input'];
};


export type ViewerPropertyArgs = {
  id: Scalars['ID']['input'];
};


export type ViewerRatePlanArgs = {
  id: Scalars['ID']['input'];
};
